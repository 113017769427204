/* eslint-disable no-console */
import { FETCH_BOT_HISTORY_ERROR, FETCH_BOT_HISTORY } from '../types';

const initialState = {
  botHistoryData: [],
  success: false,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BOT_HISTORY:
      console.log('FETCH_BOT_HISTORY Payload:', action.payload); // debug
      return {
        ...state,
        botHistoryData: action.payload,
        success: true,
        loading: false,
      };

    case FETCH_BOT_HISTORY_ERROR:
      console.error('FETCH_BOT_HISTORY_ERROR:', action.payload);
      return {
        ...state,
        success: false,
        loading: true,
      };

    default:
      console.log('Unknown Action:', action.type);
      return state;
  }
}
